import React, { useCallback, useEffect, useState, useMemo } from "react";
import './App.css';
import decks from './cards.json';
import CardGrid from './CardGrid';
import Modal from './Modal';

function App() {
  const defCard = 'default.png';
  const folder = 'cards';

  const [selectedCategory, setSelectedCategory] = useState('universal');
  const categories = Object.keys(decks);

  const handleCategoryChange = (event) => {
    const category = event.target.value;
    setSelectedCategory(category);
  };

  const imageUrls = useMemo(() => Object.keys(decks)
    .filter((deckName) => selectedCategory == deckName)
    .flatMap((deckName) => decks[deckName].map(card => `${folder}/${deckName}/${card}`))
    , [selectedCategory]);

  ////////////////////////////

  // State to hold the current images and the set of used image indices
  const [images, setImages] = useState([]);

  // Function to get random images from the array, without repeating images
  const getRandomImages = useCallback(() => {
    const randomImages = [];
    let usedIndices = [];

    while (randomImages.length < imageUrls.length) {
      const randomIndex = Math.floor(Math.random() * imageUrls.length);
      if (!usedIndices.includes(randomIndex)) {
        randomImages.push(imageUrls[randomIndex]);
        usedIndices.push(randomIndex);
      }
    }

    // If all images have been used, reset the usedIndices array
    if (usedIndices.length === imageUrls.length)
      usedIndices = [];

    setImages(randomImages);
  }, [imageUrls])

  useEffect(() => {
    getRandomImages();
  }, [])

  // Function to handle the button click
  function handleRefreshClick() {
    getRandomImages();
    toggleModal();
  }

  // State to hold the modal visibility
  const [isModalVisible, setIsModalVisible] = useState(false);

  // Function to toggle the modal visibility
  function toggleModal() {
    setIsModalVisible(!isModalVisible);
  }

  return (
    <div className="App">
      <div className="cards">
        <button onClick={toggleModal}>Выбор категории</button>
        <CardGrid images={images} imageDefault={`${folder}/${defCard}`} />
      </div>
      {isModalVisible &&
        <Modal
          defCard={defCard}
          folder={folder}
          handleCategoryChange={handleCategoryChange}
          handleRefreshClick={handleRefreshClick}
          selectedCategory={selectedCategory}
          categories={categories}
          handleClose={toggleModal}
        />
      }
    </div>
  );
}

export default App;
