import React, { useEffect, useState, useRef } from "react";

const CardFlip = ({ image, imageDefault, number }) => {
  const [isFlipped, setIsFlipped] = useState(true);
  const [isZoomed, setIsZoomed] = useState(false);
  const [cardPosition, setCardPosition] = useState({ top: 0, left: 0 });
  const cardRef = useRef(null);

  const handleFlip = () => {
    setIsFlipped(!isFlipped);
    handleZoom();
  };

  useEffect(() => {
    setIsFlipped(true);
    setIsZoomed(false);
  }, [image]);

  const handleZoom = () => {
    setIsZoomed(!isZoomed);
    const screenWidth = window.innerWidth;
    const screenHeight = window.innerHeight;

    const elemPos = cardRef.current.getBoundingClientRect();
    const scrollPos = document.body.getBoundingClientRect().top;

    const maxScroll = document.body.scrollHeight - window.innerHeight;

    const needTopCard = -(scrollPos - (elemPos.y + 150)) < 300 || -scrollPos > maxScroll - 300;
    const topDir = elemPos.y < screenHeight / 2 ? 1 : -1;

    const cardLeft = screenWidth / 2 - 125 - elemPos.x;
    const cardTop = needTopCard ? topDir * 300 : 0;

    setCardPosition({ top: cardTop, left: cardLeft });    

    if (!isZoomed)
      window.scrollTo({top: -(scrollPos - (elemPos.y + 150) + (screenHeight / 2)), behavior: 'smooth'});
  };

  const circleStyle = {
    width: '50px',
    height: '50px',
    borderRadius: '50%',
    backgroundColor: '#333',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    color: '#fff',
    fontSize: '36px',
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    zIndex: 10,
  };

  const cardStyle = {
    perspective: "1000px",
    width: "200px",
    height: "300px",
    margin: "20px",
    position: "relative",
    transition: "transform 0.6s ease-out, width 0.6s ease-out, height 0.6s ease-out",
    transform: isZoomed ? "translate("+cardPosition.left+"px, "+cardPosition.top+"px) scale(2)" : "scale(1)",
    zIndex: isZoomed ? 99 : 10,
  };

  const zoomButtonStyle = {
    position: 'absolute',
    top: '10px',
    right: '10px',
    cursor: 'pointer',
    zIndex: 10,
  };

  return (
    <div style={cardStyle} ref={cardRef}>
      <div
        style={{
          position: "absolute",
          width: "100%",
          height: "100%",
          backfaceVisibility: "hidden",
          transformStyle: "preserve-3d",
          transform: isFlipped ? "rotateY(180deg)" : "rotateY(0deg)",
          transition: "transform 0.6s ease-out",
          overflow: "hidden",
          borderRadius: "10px",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "column"
        }}
      >
        <img
          onClick={handleFlip}
          src={image}
          style={{
            width: "100%",
            height: "100%",
            objectFit: "cover",
          }}
          alt="Card front"
        />
      </div>
      <div
        style={{
          position: "absolute",
          width: "100%",
          height: "100%",
          backfaceVisibility: "hidden",
          transformStyle: "preserve-3d",
          transform: isFlipped ? "rotateY(0deg)" : "rotateY(-180deg)",
          transition: "transform 0.6s ease-out",
          overflow: "hidden",
          borderRadius: "10px",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "column",
          backgroundColor: "#f0f0f0"
        }}
      >
        <div
          style={{
            width: "100%",
            height: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            position: "relative"
          }}
        >
          <div style={circleStyle}>
            {number}
          </div>
          <img
            onClick={handleFlip}
            src={imageDefault}
            style={{
              width: "100%",
              height: "100%",
              objectFit: "cover",
            }}
            alt="Card back"
          />
        </div>
      </div>
    </div>
  );
};

export default CardFlip;
