import React from "react";
import CardFlip from "./CardFlip";

const CardGrid = ({ images, imageDefault }) => {
  return (
    <div style={{ display: "flex", flexWrap: "wrap" }}>
      {images.map((image, index) => (
        <div
          key={index}
          style={{
            flex: "0 0 20%",
            padding: "10px",
            boxSizing: "border-box",
          }}
        >
          <CardFlip image={image} imageDefault={imageDefault} number={index + 1} />
        </div>
      ))}
    </div>
  );
};

export default CardGrid;