import React from "react";
import categories from "./categories.json"; // импортируем файл

function Modal({ selectedCategory, handleCategoryChange, handleRefreshClick, handleClose }) {
  return (
    <div className="modal-background" onClick={handleClose}>
      <div className="modal-content" onClick={(e) => e.stopPropagation()}>
        <h2>Выберите категорию</h2>

        <ul>
          {/* Отображаем список категорий в столбец */}
          {Object.keys(categories).map((categoryKey) => (
            <li key={categoryKey}>
              <label>
                <input
                  type="radio"
                  name="category"
                  value={categoryKey}
                  checked={selectedCategory === categoryKey}
                  onChange={handleCategoryChange}
                />
                {categories[categoryKey]} {/* используем название категории */}
              </label>
            </li>
          ))}
        </ul>

        {/* Кнопки "Обновить карты" и "Закрыть" */}
        <div className="modal-buttons">
          <button onClick={handleRefreshClick}>Обновить карты</button>
          <button onClick={handleClose}>Закрыть</button>
        </div>
      </div>
    </div>
  );
}

export default Modal;
